/* eslint-disable no-unsafe-finally */
/* eslint-disable import/no-extraneous-dependencies */
import auth0 from "auth0-js";
import store from "@/store/index.js";
import router from "@/router/index.js";
import i18n from "@/plugins/i18n.js";
import { userProfiles } from "@/mixins/userProfiles.js";
import { googleRecaptcha } from "@/mixins/googleRecaptcha.js";
import { customersApiFunctions } from "@/mixins/customersApiMixin.js";

export const credentialsAuth = {
  mixins: [userProfiles, googleRecaptcha, customersApiFunctions],

  data() {
    return {
      webAuth: new auth0.WebAuth({
        domain: process.env.VUE_APP_AUTH0_DOMAIN,
        clientID: process.env.VUE_APP_AUTH0_CLIENTID,
        redirectUri: `${window.location.origin}/callback`,
        audience: process.env.VUE_APP_AUDIENCE,
        responseType: "token id_token",
        scope: "openid profile email",
      }),
      credentialsAuthError: false,
      credentialsServerError: false,
      credentialsWrongPasswordError: false,
      credentialsAuthLoading: false,
    };
  },

  computed: {
    credentialsExpiresAt: {
      get: () => localStorage.getItem("credentials_expires_at"),
      set: expiresIn => {
        const remaining = JSON.stringify(expiresIn * 1000 + new Date().getTime());
        localStorage.setItem("credentials_expires_at", remaining);
      },
    },
  },

  methods: {
    async credentialsLogin({
      email,
      password,
    }) {
      this.$auth.credentialsAuthError = false;
      this.$auth.credentialsWrongPasswordError = false;
      this.$auth.credentialsServerError = false;
      const normalizedEmail = email.toLowerCase().trim();
      try {
        this.credentialsAuthLoading = true;
        const isHuman = await this.verifyRecaptcha("login");
        if (isHuman) {
          this.webAuth.client.login(
            {
              realm: "Username-Password-Authentication",
              username: normalizedEmail,
              password,
              redirectUri: `${window.location.origin}/callback`,
            },
            async (err, authResult) => {
              if (authResult && authResult.accessToken && authResult.idToken) {
                localStorage.setItem("userEmail", normalizedEmail);
                localStorage.setItem("credentials_access_token", authResult.accessToken);
                this.credentialsExpiresAt = authResult.expiresIn;
                const result = await this.setUserMainProfile({
                  email: normalizedEmail,
                  noAccountCallback: this.noAccountCallback,
                  errorCallback: () => {
                    localStorage.clear();
                    this.credentialsServerError = true;
                  },
                });
                this.credentialsAuthLoading = false;
                if (!result) {
                  return;
                }
                await this.getCustomerStatus();
                window.location.replace(window.location.origin);
                return;
              }
              localStorage.clear();
              this.credentialsAuthLoading = false;
              this.credentialsAuthError = true;
            },
          );
        } else {
          this.credentialsAuthLoading = false;
        }
      } catch (e) {
        this.credentialsAuthLoading = false;
        this.credentialsServerError = true;
      }
    },

    // eslint-disable-next-line consistent-return
    async credentialsSignup(
      email,
      password,
      wrongPasswordCallback,
      noAccountCallback,
    ) {
      this.credentialsAuthError = false;
      this.credentialsWrongPasswordError = false;
      this.credentialsServerError = false;
      this.credentialsAuthLoading = true;
      const normalizedEmail = email.toLowerCase().trim();
      const isHuman = await this.verifyRecaptcha("sign_up");
      if (isHuman) {
        const loginResults = await this.createOrLogin(normalizedEmail, password);

        // Check if password is wrong
        if ("error" in loginResults) {
          wrongPasswordCallback();
        }
        localStorage.setItem("credentials_access_token", loginResults?.newAccessToken);
        this.credentialsExpiresAt = loginResults?.expiresIn;
        this.idToken = loginResults?.idToken;

        const profilesResponse = await this.getProfilesFromEmail(normalizedEmail, loginResults?.newAccessToken);
        const profiles = profilesResponse.data.emails;
        localStorage.setItem("userEmail", normalizedEmail);

        // If the email has profiles, we are going to login in the account.
        if (profiles.length > 0) {
          this.credentialsAuthLoading = false;
          return this.credentialsLogin({
            email: normalizedEmail,
            password,
          });
        }
        this.credentialsAuthLoading = false;
        // Otherwise, we are going to register the email.
        return noAccountCallback();
      }
      this.credentialsAuthLoading = false;
    },

    async createOrLogin(email, password) {
      const baseUrl = process.env.VUE_APP_AUTH0_DOMAIN;
      const headers = {
        "Content-Type": "application/json",
      };
      let data = {
        audience: process.env.VUE_APP_AUDIENCE,
        client_id: process.env.VUE_APP_AUTH0_CLIENTID,
        email,
        password,
        connection: "Username-Password-Authentication",
        grant_type: process.env.VUE_APP_AUTH0_GRANT_TYPE,
        scope: process.env.VUE_APP_AUTH0_SCOPE,
        username: email,
      };
      try {
        // In all cases, we will try to create the user in Auth0
        await this.axios.post(`${baseUrl}/dbconnections/signup`, data, { headers });
      } finally {
        try {
          data = {
            realm: "Username-Password-Authentication",
            audience: process.env.VUE_APP_AUDIENCE,
            client_id: process.env.VUE_APP_AUTH0_CLIENTID,
            grant_type: process.env.VUE_APP_AUTH0_GRANT_TYPE,
            password,
            scope: process.env.VUE_APP_AUTH0_SCOPE,
            username: email,
          };
          // We are going to login to get the accessToken and return it
          const loginResult = await this.axios.post(`${baseUrl}/oauth/token`, data, { headers });
          return {
            newEmail: email,
            newAccessToken: loginResult.data.access_token,
            newRefreshToken: loginResult.data.refresh_token,
            idToken: loginResult.data.id_token,
            expiresIn: loginResult.data.expires_in,
          };
        } catch (e2) {
          return {
            error: "Usuario o contraseña incorrecto",
          };
        }
      }
    },

    recoverPassword(email, errorCallbackFunction) {
      return this.webAuth.changePassword(
        {
          email,
          connection: "Username-Password-Authentication",
        },
        err => {
          console.log(err);
          errorCallbackFunction(err);
        },
      );
    },

    credentialsAuthenticated() {
      return (localStorage.getItem("credentials_access_token") && this.credentialsExpiresAt) &&
        (new Date().getTime() < this.credentialsExpiresAt);
    },

    noAccountCallback(email) {
      store.commit("setEmail", email);
      this.$intercom.update({
        email,
      });
      this.$intercom.trackEvent(i18n.t("signup.intercom.create.event"), {
        status_registro: i18n.t("signup.intercom.create.message"),
      });
      this.$intercom.trackEvent(i18n.t("signup.intercom.hub.event"), {
        status_registro: i18n.t("signup.intercom.hub.message"),
      });
      router.push({ name: "signupDashboard" });
    },
  },
};
