import { operationsGQLQueries } from "../gql/operationQueries.js";

export const operationsApiFunctions = {
  methods: {
    operationsHeaders(additionalHeaders = {}) {
      const token = this.$auth.accessToken;
      return {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        ...additionalHeaders,
      };
    },

    async requestRedemption(payload) {
      const data = operationsGQLQueries.createRedemption(payload);
      const response = await this.axios.post(process.env.VUE_APP_OPERATIONS_API_GRAPH, data, {
        headers: this.operationsHeaders(),
      });
      return {
        data: response.data,
        errors: response.data.errors,
      };
    },

    async requestReinvestment(payload) {
      const data = operationsGQLQueries.createReinvestment(payload);
      const response = await this.axios.post(process.env.VUE_APP_OPERATIONS_API_GRAPH, data, {
        headers: this.operationsHeaders(),
      });
      return {
        data: response.data,
        errors: response.data.errors,
      };
    },

    async createPaymentIntent(payload) {
      const data = operationsGQLQueries.createPaymentIntent(payload);
      const response = await this.axios.post(process.env.VUE_APP_OPERATIONS_API_GRAPH, data, {
        headers: this.operationsHeaders(),
      });
      return {
        data: response.data,
        errors: response.data.errors,
      };
    },

    async patchPaymentIntent(payload) {
      const data = operationsGQLQueries.patchPaymentIntent(payload);
      const response = await this.axios.post(process.env.VUE_APP_OPERATIONS_API_GRAPH, data, {
        headers: this.operationsHeaders(),
      });
      return {
        data: response.data,
        errors: response.data.errors,
      };
    },

    async fetchPendingMovements(payload) {
      const data = operationsGQLQueries.getPendingMovements(payload);
      const response = await this.axios.post(process.env.VUE_APP_OPERATIONS_API_GRAPH, data, {
        headers: this.operationsHeaders(),
      });
      if (response.data?.data) { return response.data.data.customer.pendingMovements; }
      return null;
    },

    async fetchReinvestments(payload) {
      const data = operationsGQLQueries.getReinvestments(payload);
      const response = await this.axios.post(process.env.VUE_APP_OPERATIONS_API_GRAPH, data, {
        headers: this.operationsHeaders(),
      });
      if (response.data?.data) { return response.data.data.reinvestments; }
      return null;
    },

    async fetchPendingReinvestments(payload) {
      const data = operationsGQLQueries.getPendingReinvestments(payload);
      const response = await this.axios.post(process.env.VUE_APP_OPERATIONS_API_GRAPH, data, {
        headers: this.operationsHeaders(),
      });
      if (response.data?.data) { return response.data.data.reinvestments; }
      return null;
    },

    async fetchCustomerDividends(payload) {
      const data = operationsGQLQueries.getCustomerDividends(payload);
      const response = await this.axios.post(process.env.VUE_APP_OPERATIONS_API_GRAPH, data, {
        headers: this.operationsHeaders(),
      });
      return {
        data: response.data,
        errors: response.data.errors,
      };
    },

    async createDividendAnswer(payload) {
      const data = operationsGQLQueries.createDividendAnswer(payload);
      const response = await this.axios.post(process.env.VUE_APP_OPERATIONS_API_GRAPH, data, {
        headers: this.operationsHeaders(),
      });
      return {
        data: response.data,
        errors: response.data.errors,
      };
    },
  },
};
