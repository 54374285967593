const FUNDS = {
  9118: {
    name: "Compass DVA Multiaxis",
    fundRun: 9118,
    shortName: "Multiaxis",
    currency: "USD",
    fixedFee: "1,2%",
    fundType: "equity",
    geoDistribution: "Global",
    rut: "76.121.258-3",
    bank: "BCI - Banco de Crédito e Inversiones",
    accountNumber: "28992393",
    email: "clientes@dvacapital.com",
    timeHorizon: "LONG",
    icon: "web",
    redemptionPeriod: "fromFiveDays",
    color: "#4E5FF1",
    highestRecommendedProfilingScore: 14,
  },

  9362: {
    name: "Compass Acciones Chilenas",
    shortName: "Acciones Chilenas",
    fundRun: 9362,
    currency: "CLP",
    fixedFee: "1,19%",
    fundType: "equity",
    geoDistribution: "Chile",
    rut: "76.618.593-2",
    bank: "BCI - Banco de Crédito e Inversiones",
    accountNumber: "10732454",
    email: "clientes@dvacapital.com",
    timeHorizon: "LONG",
    icon: "star-outline",
    redemptionPeriod: "fromTwoDays",
    color: "#00AA9D",
    highestRecommendedProfilingScore: 14,
  },

  9725: {
    name: "Compass DVA Silicon Fund",
    shortName: "Silicon Fund",
    fundRun: 9725,
    currency: "USD",
    fixedFee: "0.95%",
    fundType: "equity",
    geoDistribution: "Global",
    rut: "76.989.370-9",
    bank: "BCI - Banco de Crédito e Inversiones",
    accountNumber: "28997034",
    email: "clientes@dvacapital.com",
    timeHorizon: "LONG",
    icon: "memory",
    redemptionPeriod: "fromFiveDays",
    color: "#FF0079",
    highestRecommendedProfilingScore: 14,
  },

  9937: {
    name: "Compass DVA Medtech",
    shortName: "Medtech",
    fundRun: 9937,
    currency: "USD",
    fixedFee: "1,19%",
    fundType: "equity",
    geoDistribution: "Global",
    rut: "77.235.629-3",
    bank: "BCI - Banco de Crédito e Inversiones",
    accountNumber: "30389631",
    email: "clientes@dvacapital.com",
    timeHorizon: "LONG",
    icon: "needle",
    redemptionPeriod: "fromFiveDays",
    color: "#832FED",
    highestRecommendedProfilingScore: 14,
  },

  9958: {
    name: "Compass DVA Deuda Privada USA",
    shortName: "Deuda Privada USA",
    fundRun: 9958,
    currency: "USD",
    fixedFee: "1,19%",
    fundType: "alternatives",
    geoDistribution: "EEUU",
    rut: "77.274.873-6",
    bank: "BCI - Banco de Crédito e Inversiones",
    accountNumber: "30390290",
    email: "clientes@dvacapital.com",
    timeHorizon: "MEDIUM",
    icon: "shield-star-outline",
    redemptionPeriod: "fromFiveDays",
    color: "#135D75",
    highestRecommendedProfilingScore: 14,
  },

  10431: {
    name: "Compass Liquidez FM",
    shortName: "Liquidez",
    fundRun: 10431,
    currency: "CLP",
    fixedFee: "0,8925%",
    fundType: "fixedIncome",
    geoDistribution: "Chile",
    rut: "77.726.524-5",
    bank: "BCI - Banco de Crédito e Inversiones",
    accountNumber: "10732446",
    email: "clientes@dvacapital.com",
    timeHorizon: "SHORT",
    icon: "lock-outline",
    redemptionPeriod: "oneDay",
    color: "#C50076",
    highestRecommendedProfilingScore: null,
  },

  10275: {
    name: "Compass Equilibrio",
    shortName: "Equilibrio",
    fundRun: 10275,
    currency: "CLP",
    fixedFee: "1.19%",
    fundType: "mixedFunds",
    geoDistribution: "Global",
    rut: "77.591.193-K",
    bank: "BCI - Banco de Crédito e Inversiones",
    accountNumber: "10727019",
    email: "clientes@dvacapital.com",
    timeHorizon: "SHORT",
    icon: "flag-outline",
    redemptionPeriod: "fromTwoDays",
    color: "#CA9328",
    highestRecommendedProfilingScore: 11,
  },

  10348: {
    name: "Compass DVA E-Fund",
    shortName: "E-Fund",
    fundRun: 10348,
    currency: "USD",
    fixedFee: "1.19%",
    fundType: "equity",
    geoDistribution: "Global",
    rut: "77.656.122-3",
    bank: "BCI - Banco de Crédito e Inversiones",
    accountNumber: "30398274",
    email: "clientes@dvacapital.com",
    timeHorizon: "LONG",
    icon: "lightning-bolt-outline",
    redemptionPeriod: "fromFiveDays",
    color: "#1EC26A",
    highestRecommendedProfilingScore: 14,
  },
};

export default {
  methods: {
    getFundStaticData(run) {
      return FUNDS[run];
    },

    getRunList() {
      return Object.keys(FUNDS);
    },

    getFundValues() {
      return Object.values(FUNDS);
    },

  },
};
