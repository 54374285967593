import store from "@/store/index.js";
import fundsMixin from "@/mixins/funds.js";
import { customerGQLQueries } from "../gql/customerQueries.js";

const { format } = require("rut.js");

export const customersApiFunctions = {
  methods: {
    customersHeaders(additionalHeaders = {}, bearerToken = null) {
      const token = bearerToken || this.$auth.accessToken;
      if (!token) return null;

      return {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        ...additionalHeaders,
      };
    },

    multipartCustomersHeaders(additionalHeaders = {}) {
      const token = this.$auth.accessToken;
      return {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
        ...additionalHeaders,
      };
    },

    async getCustomerProfile() {
      const data = customerGQLQueries.getCustomerProfile({
        accountId: this.$auth.getProfile().id,
      });
      const response = await this.axios.post(process.env.VUE_APP_GRAPH, data, {
        headers: this.customersHeaders(),
      });
      return {
        data: response.data?.data,
        errors: response.data.errors,
      };
    },

    async getProfilesFromEmail(payload, token = null) {
      const data = customerGQLQueries.getProfilesFromEmail(payload);

      const headers = this.customersHeaders({}, token);

      if (!headers) {
        return {
          data: { emails: [] }, errors: [{ message: "No token provided" }],
        };
      }

      const response = await this.axios.post(process.env.VUE_APP_GRAPH, data, {
        headers,
      });

      return {
        data: response.data?.data,
        errors: response.data.errors,
      };
    },

    async getCustomerData() {
      const fundRuns = fundsMixin.methods.getRunList().map(run => Number(run));

      const data = customerGQLQueries.getCustomerData({
        accountId: this.$auth.getProfile().id,
        fundRuns,
      });

      const response = await this.axios.post(process.env.VUE_APP_GRAPH, data, {
        headers: this.customersHeaders(),
      });
      return {
        data: response.data?.data,
        errors: response.data.errors,
      };
    },

    async getCustomerMovementsData() {
      const fundRuns = fundsMixin.methods.getRunList().map(run => Number(run));

      const data = customerGQLQueries.getCustomerMovementsData({
        accountId: this.$auth.getProfile().id,
        fundRuns,
      });

      const response = await this.axios.post(process.env.VUE_APP_GRAPH, data, {
        headers: this.customersHeaders(),
      });
      return {
        data: response.data?.data,
        errors: response.data.errors,
      };
    },

    async getBalanceHistoryData() {
      const data = customerGQLQueries.getBalanceHistoryData({
        accountId: this.$auth.getProfile().id,
      });
      const response = await this.axios.post(process.env.VUE_APP_GRAPH, data, {
        headers: this.customersHeaders(),
      });
      return {
        data: response.data?.data,
        errors: response.data.errors,
      };
    },

    async changePassword(payload) {
      const data = customerGQLQueries.changePassword(payload);
      const response = await this.axios.post(process.env.VUE_APP_GRAPH, data, {
        headers: this.customersHeaders(),
      });
      return {
        data: response.data?.data,
        errors: response.data.errors,
      };
    },

    async patchCustomerData({ payload, TFAAuthorization, passwordAuthorization }) {
      const {
        name,
        lastName,
        isPep,
        isCrs,
        isFatca,
        capitalOriginCountry,
        occupation,
        profession,
        selfEmployed,
        documentNumber,
        capitalOrigin,
        salaryRange,
        netWorth,
        maritalStatus,
        phone,
        streetName,
        houseNumber,
        interiorNumber,
        locality,
        region,
        country,
        accountNumber,
        currency,
        bankId,
        bankAccountTypeId,
        conjugalRegime,
        birthCountry,
        nationality,
        profiling,
        tinCountry,
        tinIdentification,
        spouseIdentifier,
        spouseName,
        spouseFamilyName,
      } = payload;
      let customerInput;
      let phoneInput;
      let addressInput;
      let bankAccountInput;
      let taxIdentificationNumberInput;
      let spouseInput;

      if (name ||
         lastName || isPep !== undefined || isCrs !== undefined || isFatca !== undefined || occupation || profession ||
        capitalOrigin || netWorth || salaryRange || maritalStatus || birthCountry ||
        nationality || capitalOriginCountry || selfEmployed !== undefined || documentNumber || profiling
      ) {
        customerInput = {
          ...(name ? { name } : {}),
          ...(lastName ? { lastName } : {}),
          ...(isPep !== undefined ? { isPep } : {}),
          ...(isCrs !== undefined ? { isCrs } : {}),
          ...(isFatca !== undefined ? { isFatca } : {}),
          ...(capitalOriginCountry ? { capitalOriginCountry } : {}),
          ...(occupation ? { occupation } : {}),
          ...(profession ? { profession } : {}),
          ...(selfEmployed !== undefined ? { selfEmployed } : {}),
          ...(documentNumber ? { documentNumber } : {}),
          ...(capitalOrigin ? { capitalOrigin } : {}),
          ...(maritalStatus ? { maritalStatus } : {}),
          ...(netWorth ? { netWorth } : {}),
          ...(salaryRange ? { salaryRange } : {}),
          ...(conjugalRegime ? { conjugalRegime } : {}),
          ...(birthCountry ? { birthCountry } : {}),
          ...(nationality ? { nationality } : {}),
          ...(profiling ? { profiling } : {}),
        };
      }
      if (phone) {
        phoneInput = {
          phone,
        };
      }
      if (streetName || houseNumber || typeof interiorNumber === "string" || locality || region || country) {
        addressInput = {
          streetName,
          houseNumber,
          interiorNumber,
          locality,
          region,
          country,
        };
      }
      if (accountNumber || currency || bankId || bankAccountTypeId) {
        bankAccountInput = {
          accountNumber,
          currency,
          bankId,
          bankAccountTypeId,
        };
      }

      if (tinCountry || tinIdentification) {
        taxIdentificationNumberInput = {
          ...(tinCountry ? { country: tinCountry } : {}),
          ...(tinIdentification ? { identification: tinIdentification } : {}),
        };
      }

      if (spouseIdentifier || spouseName || spouseFamilyName) {
        spouseInput = {
          ...(spouseIdentifier ? { identifier: spouseIdentifier } : {}),
          ...(spouseName ? { name: spouseName } : {}),
          ...(spouseFamilyName ? { lastName: spouseFamilyName } : {}),
        };
      }

      const data = customerGQLQueries.patchCustomerData({
        id: this.$auth.getProfile().id,
        ...(customerInput ? { customerInput } : {}),
        ...(phoneInput ? { phoneInput } : {}),
        ...(addressInput ? { addressInput } : {}),
        ...(bankAccountInput ? { bankAccountInput } : {}),
        ...(taxIdentificationNumberInput ? { taxIdentificationNumberInput } : {}),
        ...(spouseInput ? { spouseInput } : {}),
      });

      const response = await this.axios.post(process.env.VUE_APP_GRAPH, data, {
        headers: this.customersHeaders({
          ...(passwordAuthorization ? { "x-password-verification": passwordAuthorization } : {}),
          ...(TFAAuthorization ? { "x-2fa-token": TFAAuthorization } : {}),
        }),
      });
      return {
        data: response.data?.data,
        errors: response.data.errors,
      };
    },

    async createSpouse(payload) {
      try {
        const customerId = this.$auth.getProfile().id;

        const query = customerGQLQueries.createSpouse({ customerId, ...payload });

        const response = await this.axios.post(process.env.VUE_APP_GRAPH, query, {
          headers: this.customersHeaders(),
        });
        return {
          data: response.data?.data,
          errors: response.data.errors,
        };
      } catch (error) {
        return {
          data: [],
          errors: { error: true, errorType: "internalError" },
        };
      }
    },

    async patchSpouse(payload) {
      try {
        const query = customerGQLQueries.patchSpouse(payload);

        const response = await this.axios.post(process.env.VUE_APP_GRAPH, query, {
          headers: this.customersHeaders(),
        });
        return {
          data: response.data?.data,
          errors: response.data.errors,
        };
      } catch (error) {
        return {
          data: [],
          errors: { error: true, errorType: "internalError" },
        };
      }
    },

    async updateIdDocument(payload) {
      try {
        const formData = new FormData();
        const query = customerGQLQueries.uploadIdentificationDocument({
          customerId: this.$auth.getProfile().id,
          frontIdentificationDocument: null,
          backIdentificationDocument: null,
        });

        formData.append("operations", JSON.stringify(
          query,
        ));

        formData.append("map", JSON.stringify({
          0: ["variables.frontIdentificationDocument"],
          1: ["variables.backIdentificationDocument"],
        }));

        formData.append("0", payload.frontIdentificationDocument, "frontal.jpg");
        formData.append("1", payload.backIdentificationDocument, "trasero.jpg");

        const response = await this.axios.post(process.env.VUE_APP_GRAPH, formData, {
          headers: this.multipartCustomersHeaders(),
        });

        return {
          data: response.data?.data,
          errors: response.data.errors,
        };
      } catch (e) {
        return {
          data: [],
          errors: { error: true, errorType: "internalError" },
        };
      }
    },

    async signCompassContract() {
      const query = customerGQLQueries.signCompassContract({
        customerId: this.$auth.getProfile().id,
      });

      try {
        const response = await this.axios.post(process.env.VUE_APP_GRAPH, query, {
          headers: this.customersHeaders(),
        });

        if (response.data?.data?.signCompassContract?.success) {
          store.commit("setSignupStatus", { completedContractCompass: true });
        }

        return {
          data: response.data?.data,
          error: (response.data?.errors && response.data?.errors.length > 0),
        };
      } catch (error) {
        console.log(error);
        return {
          data: [],
          error: true,
        };
      }
    },

    async signUpValidation(payload) {
      try {
        const formData = new FormData();

        const query = customerGQLQueries.signUpValidation({
          ...payload,
          front: null,
          back: null,
        });

        formData.append("operations", JSON.stringify(
          query,
        ));

        formData.append("map", JSON.stringify({
          0: ["variables.front"],
          1: ["variables.back"],
        }));

        formData.append("0", payload.front, "frontal.jpg");
        formData.append("1", payload.back, "trasero.jpg");

        const response = await this.axios.post(process.env.VUE_APP_GRAPH, formData, {
          headers: this.multipartCustomersHeaders(),
        });

        if (response.data?.data?.signUpValidation?.success) {
          this.$store.commit("setSignupStatus", { completedValidation: true });
        }

        const error = response.data?.errors && response.data?.errors.length > 0;
        const errorCode = error ? response.data?.errors[0]?.extensions?.code || "UNKNOWN_ERROR" : null;

        return {
          data: response.data?.data,
          error,
          errorCode,
        };
      } catch (error) {
        console.error(error);
        return {
          data: [],
          error: true,
          errorCode: error.response?.data?.errors[0]?.extensions?.code || "UNKNOWN_ERROR",
        };
      }
    },

    async signUpIdentification(payload) {
      const query = customerGQLQueries.signUpIdentification(payload);

      try {
        const response = await this.axios.post(process.env.VUE_APP_GRAPH, query, {
          headers: this.customersHeaders(),
        });

        if (response.data?.data?.signUpIdentification?.success) {
          this.$store.commit("setSignupStatus", { completedIdentification: true });
        }

        const error = response.data?.errors && response.data?.errors.length > 0;
        const errorCode = error ? response.data?.errors[0]?.extensions?.code || "UNKNOWN_ERROR" : null;

        return {
          data: response.data?.data,
          error,
          errorCode,
        };
      } catch (error) {
        console.error(error);

        return {
          data: [],
          error: true,
          errorCode: error.response?.data?.errors[0]?.extensions?.code || "UNKNOWN_ERROR",
        };
      }
    },

    async signUpProfiling(payload) {
      const query = customerGQLQueries.signUpProfiling(payload);

      try {
        const response = await this.axios.post(process.env.VUE_APP_GRAPH, query, {
          headers: this.customersHeaders(),
        });

        if (response.data?.data?.signUpProfiling?.success) {
          this.$store.commit("setSignupStatus", { completedProfiling: true });
        }

        const error = response.data?.errors && response.data?.errors.length > 0;
        const errorCode = error ? response.data?.errors[0]?.extensions?.code || "UNKNOWN_ERROR" : null;

        return {
          data: response.data?.data,
          error,
          errorCode,
        };
      } catch (error) {
        console.error(error);
        return {
          data: [],
          error: true,
          errorCode: error.response?.data?.errors[0]?.extensions?.code || "UNKNOWN_ERROR",
        };
      }
    },

    async signUpContract(payload) {
      const query = customerGQLQueries.signUpContract(payload);

      try {
        const response = await this.axios.post(process.env.VUE_APP_GRAPH, query, {
          headers: this.customersHeaders(),
        });

        if (response.data?.data?.signUpContract?.success) {
          this.$store.commit("setSignupStatus", { completedContractCompass: true, completedContract: true });
        }

        const error = response.data?.errors && response.data?.errors.length > 0;
        const errorCode = error ? response.data?.errors[0]?.extensions?.code || "UNKNOWN_ERROR" : null;

        return {
          data: response.data?.data,
          error,
          errorCode,
        };
      } catch (error) {
        console.error(error);
        return {
          data: [],
          error: true,
          errorCode: error.response?.data?.errors[0]?.extensions?.code || "UNKNOWN_ERROR",
        };
      }
    },

    async getCustomerStatus() {
      const email = localStorage.getItem("userEmail");

      const { isSignedUp, signupRut } = store.getters;

      const customerIdentifier = this.$auth.getProfile()?.identifier || signupRut;

      const request = customerGQLQueries.getCustomerStatus({
        ...(customerIdentifier && { customerIdentifier }),
        email,
      });

      try {
        const response = await this.axios.post(process.env.VUE_APP_GRAPH, request, {
          headers: this.customersHeaders(),
        });

        const status = response.data.data?.customerStatus;

        store.commit("setSignupStatus", {
          rut: status?.rut ? format(status.rut) : undefined,
          completedValidation: status?.completedValidation || isSignedUp,
          completedIdentification: status?.completedIdentification || isSignedUp || status?.completedContract,
          completedProfiling: status?.completedProfiling || isSignedUp || status?.completedContract,
          completedContract: status?.completedContract || isSignedUp,
          completedContractCompass: status?.completedContractCompass,
          contractDate: status?.contractDate,
          compassContractDate: status?.compassContractDate,
          pendingProfilingIdentificationReview: status?.pendingProfilingIdentificationReview,
          acceptedProfilingIdentificationReview: status?.acceptedProfilingIdentificationReview,
        });

        return { status, errors: !status };
      } catch (error) {
        console.error(error);
        store.commit("setSignupStatus", {
          completedContractCompass: true,
        });
        return { status: null, errors: [{ message: error }] };
      }
    },

    async createAccountStatement(reportDate) {
      const query = customerGQLQueries.createAccountStatement({
        customerId: Number(this.$auth.getProfile().id),
        reportDate,
      });

      try {
        const response = await this.axios.post(process.env.VUE_APP_GRAPH, query, {
          headers: this.customersHeaders(),
        });

        const accountStatementData = response.data?.data?.createAccountStatement;

        return {
          data: accountStatementData?.file,
          error: !accountStatementData?.success,
        };
      } catch (error) {
        console.error(error);
        return {
          data: null,
          error: true,
        };
      }
    },

    async createCustomerDeclaration(payload) {
      const { signupRut } = store.getters;
      const customerIdentifier = this.$auth.getProfile()?.identifier || signupRut;

      const body = {
        customerIdentifier,
        ...payload,
      };

      const query = customerGQLQueries.createCustomerDeclaration(body);
      try {
        const response = await this.axios.post(process.env.VUE_APP_GRAPH, query, {
          headers: this.customersHeaders(),
        });

        const declarationData = response.data?.data?.createCustomerDeclaration;

        return {
          data: declarationData,
          error: !declarationData?.success,
        };
      } catch (error) {
        console.error(error);
        return {
          data: null,
          error: true,
        };
      }
    },

  },
};
