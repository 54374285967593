/* eslint-disable import/extensions */
/* eslint-disable function-paren-newline */
/* eslint-disable implicit-arrow-linebreak */
// eslint-disable-next-line import/no-unresolved

import dayjs from "dayjs";
import isboolean from "lodash.isboolean";
import Vue from "vue";

import fundsMixin from "@/mixins/funds.js";
import { bankSelects } from "@/mixins/bankSelects.js";
import { get } from "../utils/vuex.js";

const actions = {
  setAuthenticatedStatus({ commit }, payload) {
    commit("setAuthenticatedStatus", payload);
  },

  setFundsData({ commit }, payload) {
    commit("setFundsData", payload);
  },

  setFundsMovementsData({ commit }, payload) {
    commit("setFundsMovementsData", payload);
  },

  setBalanceHistoryData({ commit }, payload) {
    commit("setBalanceHistoryData", payload);
  },

  // Includes exchangeRate, quotaValue.
  setFundsDetailedData({ commit }, payload) {
    commit("setFundsDetailedData", payload);
  },

  setProfileData({ commit }, payload) {
    commit("setProfileData", payload);
  },

  setBalanceDetails({ commit }, payload) {
    commit("setBalanceDetails", payload);
  },

  setTotalResults({ commit }, payload) {
    commit("setTotalResults", payload);
  },

  setCurrency({ commit }, payload) {
    commit("setCurrency", payload);
  },

  setProfile({ commit }, payload) {
    commit("setProfile", payload);
  },

  setLoading({ commit }, payload) {
    commit("setLoading", payload);
  },

  setLoadingBalanceHistory({ commit }, payload) {
    commit("setLoadingBalanceHistory", payload);
  },

  setLoadingProfile({ commit }, payload) {
    commit("setLoadingProfile", payload);
  },

  setLoadingPendingMovements({ commit }, payload) {
    commit("setLoadingPendingMovements", payload);
  },

  setLoadingFundsMovements({ commit }, payload) {
    commit("setLoadingFundsMovements", payload);
  },

  setLoadingReinvestments({ commit }, payload) {
    commit("setLoadingReinvestments", payload);
  },

  cleanStore({ commit }, payload) {
    commit("cleanStore", payload);
  },

  setCustomerChoices({ commit }, payload) {
    commit("setCustomerChoices", payload);
  },

  setPendingMovementsData({ commit }, payload) {
    commit("setPendingMovementsData", payload);
  },

  setReinvestmentsData({ commit }, payload) {
    commit("setReinvestmentsData", payload);
  },

  setFintocStatus({ commit }, payload) {
    commit("setFintocStatus", payload);
  },

  setUserData({ commit }, payload) {
    commit("setUserData", payload);
  },

  setQueryParams({ commit }) {
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });
    const referralParam = params?.referral;
    const emailParam = params?.email;
    const targetFundParam = params?.fundRun;
    const utmSourceParam = params?.utm_source;
    const utmContentParam = params?.utm_content;
    const utmCampaignParam = params?.utm_campaign;

    const utmParams = { utmSourceParam, utmContentParam, utmCampaignParam };

    if (targetFundParam) {
      commit("setTargetFund", targetFundParam);
    }

    if (emailParam) {
      commit("setProposedEmail", emailParam);
    }

    if (referralParam) {
      commit("setReferralSource", referralParam);
    }

    if (Object.values(utmParams).some(param => param)) {
      commit("setUtmParams", utmParams);
    }
  },

  deleteParams({ commit }) {
    window.history.replaceState({}, document.title, "/");
    commit("setTargetFund", null);
    commit("setReferralSource", null);
  },

  setTargetFund({ commit }, payload) {
    commit("setTargetFund", payload);
  },

  setRedemptionData({ commit }, payload) {
    commit("setRedemptionData", payload);
  },

  setReinvestmentData({ commit }, payload) {
    commit("setReinvestmentData", payload);
  },

  setSignupStatus({ commit }, payload) {
    commit("setSignupStatus", payload);
  },

  setProfilingCustomerAnswer({ commit }, payload) {
    commit("setProfilingCustomerAnswer", payload);
  },

  setManualProfilingCustomerAnswer({ commit }, payload) {
    commit("setManualProfilingCustomerAnswer", payload);
  },

  setProfilingCustomerReferralAnswer({ commit }, payload) {
    commit("setProfilingCustomerReferralAnswer", payload);
  },

  setProfilingCustomerLaborData({ commit }, payload) {
    commit("setProfilingCustomerLaborData", payload);
  },

  setProfilingCustomerCapitalOrigin({ commit }, payload) {
    commit("setProfilingCustomerCapitalOrigin", payload);
  },

  setProfilingCustomerNetWorth({ commit }, payload) {
    commit("setProfilingCustomerNetWorth", payload);
  },

  setProfilingCustomerSalaryRange({ commit }, payload) {
    commit("setProfilingCustomerSalaryRange", payload);
  },

  setProfilingCustomerDeclarations({ commit }, payload) {
    commit("setProfilingCustomerDeclarations", payload);
  },

  setContractReinvestDividends({ commit }, payload) {
    commit("setContractReinvestDividends", payload);
  },

  setIdDocumentImages({ commit }, payload) {
    commit("setIdentificationDocumentImages", payload);
  },

  setIdentifier({ commit }, payload) {
    commit("setIdentifier", payload);
  },

  setPersonal({ commit }, payload) {
    commit("setPersonal", payload);
  },

  setSpouse({ commit }, payload) {
    commit("setSpouse", payload);
  },

  setConjugalRegime({ commit }, payload) {
    commit("setConjugalRegime", payload);
  },

  setContactData({ commit }, payload) {
    commit("setContactData", payload);
  },

  setInvestData({ commit }, payload) {
    commit("setInvestData", payload);
  },

  setDividendDeclarationAnswer({ commit }, payload) {
    commit("setDividendDeclarationAnswer", payload);
  },

  setCustomerDividendsData({ commit }, payload) {
    commit("setCustomerDividendsData", payload);
  },

  setLoadingCustomerDividends({ commit }, payload) {
    commit("setLoadingCustomerDividends", payload);
  },

  setInvestTime({ commit }, payload) {
    commit("setInvestTime", payload);
  },

  setBankAccount({ commit }, payload) {
    commit("setBankAccount", payload);
  },

  setAccountData({ commit }, payload) {
    commit("setAccountData", payload);
  },

  setSpouseData({ commit }, payload) {
    commit("setSpouseData", payload);
  },

  setContactAddressData({ commit }, payload) {
    commit("setContactAddressData", payload);
  },

  setDeclarationsData({ commit }, payload) {
    commit("setDeclarationsData", payload);
  },

  saveMutationError({ commit }, payload) {
    commit("saveMutationError", payload);
  },

  resetMutationErrors({ commit }) {
    commit("resetMutationErrors");
  },

  // Route stack actions

  addToRouteStack({ commit }, payload) {
    commit("addToRouteStack", payload);
  },

  popFromRouteStack({ commit }) {
    commit("popFromRouteStack");
  },

  cleanRouteStack({ commit }) {
    commit("cleanRouteStack");
  },

  popFromMutationErrors({ commit }) {
    commit("popFromMutationErrors");
  },

  deleteFromMutationErrors({ commit }, payload) {
    commit("deleteFromMutationErrors", payload);
  },
};

const getters = {
  currentResults: (state, getters) => {
    if (getters.fundsResults.length === 0) {
      return null;
    }

    return getters.fundsResults.reduce((total, item) => total + item.fundCurrentResult, 0);
  },

  historicalResults: (state, getters) => {
    if (getters.fundsResults.length === 0) {
      return null;
    }

    return getters.fundsResults.reduce((total, item) => total + item.fundHistoricalResult, 0);
  },

  withdrawals: (state, getters) => {
    if (getters.fundsResults.length === 0) {
      return null;
    }

    return getters.fundsResults.reduce((total, item) => total + item.fundWithdrawals, 0);
  },

  fundsResults: (state, getters) => {
    if (getters.balance.length === 0) {
      return [];
    }

    function getSerieBalance(series) {
      const result = {
        currentResults: 0,
        historicalResults: 0,
        deposits: 0,
        current: 0,
        withdrawals: 0,
        totalDistributions: 0,
        distributionReinvestments: 0,
        currentDistributionReinvestments: 0,
        withdrawnResults: 0,
      };

      series.forEach(serie => {
        serie.forEach(item => {
          switch (item.balanceType) {
          case "CURRENT_RESULT":
            result.currentResults += item.currencyAmount;
            result.withdrawnResults -= item.currencyAmount;
            break;
          case "RESULT":
            result.historicalResults += item.currencyAmount;
            result.withdrawnResults += item.currencyAmount;
            break;
          case "CURRENT":
            result.current += item.currencyAmount;
            break;
          case "DEPOSITS":
            result.deposits += item.currencyAmount;
            break;
          case "WITHDRAWALS":
            result.withdrawals += item.currencyAmount;
            break;
          case "DISTRIBUTIONS":
            result.totalDistributions += item.currencyAmount;
            break;
          case "DISTRIBUTION_REINVESTMENTS":
            result.distributionReinvestments += item.currencyAmount;
            break;
          case "CURRENT_DISTRIBUTION_REINVESTMENTS":
            result.currentDistributionReinvestments += item.currencyAmount;
            break;
          default:
            break;
          }
        });
      });

      return result;
    }

    function getQuotasBySeries(seriesArray) {
      const seriesData = seriesArray
        .filter(serie => serie.balances.length > 0)
        .map(serie => {
          const balance = serie.balances.find(b => b.balanceType === "CURRENT");
          return {
            seriesName: serie.name,
            symbol: serie.symbol,
            quotasAmount: balance?.quotasAmount,
            quotaValue: balance?.quotaValue,
            exchangeRate: balance?.exchangeRate,
            currency: balance?.currency,
          };
        });
      return seriesData;
    }

    return getters.balance
      // Conservador fund was merged in Equilibrio, so the balance should not retrieved.
      .filter(fund => Number(fund.run) !== 10074)
      .map(fund => {
        const serieBalance = getSerieBalance(fund.series.map(serie => serie.balances));

        return {
          fundShortName: fund.shortName,
          fundRun: fund.run,
          fundCurrentResult: serieBalance.currentResults,
          fundHistoricalResult: serieBalance.historicalResults,
          fundCurrent: serieBalance.current,
          fundDeposits: serieBalance.deposits,
          fundWithdrawals: serieBalance.withdrawals,
          fundDistributions: serieBalance.totalDistributions,
          fundDistributionReinvestments: serieBalance.distributionReinvestments,
          fundCurrentDistributionReinvestments: serieBalance.currentDistributionReinvestments,
          fundWithdrawnResult: serieBalance.withdrawnResults,
          fundQuotas: getQuotasBySeries(fund.series),
        };
      });
  },

  fundsBalances: (state, getters) => {
    if (getters.fundsResults.length === 0) {
      return {};
    }

    const fundsBalances = {};
    getters.fundsResults.forEach(fundResult => {
      const { fundRun } = fundResult;
      fundsBalances[fundRun] = fundResult;
    });

    return fundsBalances;
  },

  currency: get("currency"),

  balance: state => {
    if (!state.fundsData) {
      return [];
    }

    return state.fundsData;
  },

  // customerCurrent: get("currentBalance"),
  // Funds where the customer is invested. To see all funds look for unfilteredFunds
  funds: state => {
    if (!state.fundsData) {
      return [];
    }
    function hasQuotas(fundData) {
      if (
        fundData.series.some(fundSeries => {
          const seriesCurrentBalance = fundSeries.balances.find(balance => balance.balanceType === "CURRENT");
          if (seriesCurrentBalance && seriesCurrentBalance.quotasAmount > 0) {
            return true;
          }
          return false;
        })
      ) {
        return true;
      }
      return false;
    }
    return state.fundsData.filter(fundData => hasQuotas(fundData));
  },

  // Funds where the customer has no quotas
  otherFunds: state => {
    if (!state.fundsData) {
      return [];
    }
    function hasNoQuotas(fundData) {
      if (
        fundData.series.some(fundSeries => {
          const seriesCurrentBalance = fundSeries.balances.find(balance => balance.balanceType === "CURRENT");
          if (seriesCurrentBalance && seriesCurrentBalance.quotasAmount > 0) {
            return true;
          }
          return false;
        })
      ) {
        return false;
      }
      return true;
    }
    return state.fundsData.filter(fundData => hasNoQuotas(fundData));
  },

  unfilteredFunds: state => state.fundsData,

  balanceDetailsData: state => {
    const balanceDetails = {};

    if (!state.fundsData) {
      return balanceDetails;
    }

    const hasDeposits = series =>
      series?.balances.some(balance => balance.balanceType === "DEPOSITS" && balance.currencyAmount > 0);

    state.fundsData
      .filter(fund => fund.series.some(hasDeposits))
      .forEach(fund => {
        const fundRun = fund.run;

        fund.series.forEach(series => {
          if (!hasDeposits(series)) {
            return;
          }

          series.balances.forEach(seriesBalance => {
            const { currencyAmount, balanceType } = seriesBalance;

            if (!balanceDetails[fundRun]) {
              balanceDetails[fundRun] = {};
            }
            if (!balanceDetails[fundRun][balanceType]) {
              balanceDetails[fundRun][balanceType] = {
                fundRun: fund.run,
                fundShortName: fund.shortName,
                fundAcronym: fund.acronym,
                currencyAmount: 0,
              };
            }
            balanceDetails[fundRun][balanceType].currencyAmount += currencyAmount;
          });
        });
      });

    return balanceDetails;
  },

  customerCurrent: (state, getters) => {
    const balancesList = [];
    if (!state.fundsData) {
      return [];
    }

    if (typeof getters.funds.forEach !== "undefined") {
      getters.funds.forEach(fund => {
        // check if the data is loaded
        fund.series.forEach(series => {
          series.balances.forEach(balance => {
            if (balance.balanceType !== "CURRENT") {
              return;
            }
            const balanceData = { ...balance };
            // destructurates the data to be hanldled easyly
            balanceData.seriesName = series.name;
            balanceData.seriesId = series.id;
            balanceData.seriesFantasyName = series.fantasyName;
            balanceData.fundId = fund.id;
            balanceData.fundRun = fund.run;
            balanceData.fundAcronym = fund.acronym;
            balanceData.fundShortName = fund.shortName;
            balanceData.fundName = fund.name;
            balanceData.fundCurrency = series.currency;
            balanceData.symbol = series.symbol;
            balancesList.push(balanceData);
          });
        });
      });
    }
    return balancesList;
  },

  closeDate: (state, getters) => {
    if (getters.customerCurrent.length > 0) {
      const balancesWithQuotas = getters.customerCurrent
        .filter(balance => balance.currencyAmount > 0);
      const dates = balancesWithQuotas.map(fundBalance => fundBalance?.date);
      if (dates.length > 0) return dates.reduce((a, b) => (a < b ? a : b));
    }
    // This return is met when the user had quotas in the past but now his/her total balance is at 0
    // Also met when the user is a new customer. In this case just return yesterdays date.
    return dayjs().subtract(1, "day").format("YYYY-MM-DD");
  },

  movements: state => {
    const movements = [];
    if (!state.fundsMovementsData) {
      return movements;
    }
    state.fundsMovementsData.forEach(fund =>
      fund.series.forEach(singleSeries =>
        singleSeries.movements.forEach(movement => {
          const formattedMovement = movement;
          formattedMovement.series = { ...singleSeries };
          formattedMovement.series.fund = { ...fund };
          delete formattedMovement.series.pendingMovements;
          delete formattedMovement.series.fund.series;
          movements.push(formattedMovement);
        }),
      ),
    );
    const MOVEMENT_PRIORITY = {
      SUBSCRIPTION: 1,
      DISTRIBUTION: 1,
      REDEMPTION: 1,
      INTERNAL_INCOMING_TRANSFER: 2,
      INTERNAL_OUTBOUND_TRANSFER: 1,
      OUTBOUND_TRANSFER: 1,
      INCOMING_TRANSFER: 2,
      CAPITAL_DISTRIBUTION: 1,
      PENDING_REDEMPTION: 3,
      DISTRIBUTION_REINVESTMENT: 2,
      REINVESTMENT_SUBSCRIPTION: 2,
      REINVESTMENT_REDEMPTION: 1,
    };
    movements.sort(
      (a, b) => MOVEMENT_PRIORITY[b.movementType] - MOVEMENT_PRIORITY[a.movementType],
    );
    movements.sort((a, b) => new Date(b.settlementDate) - new Date(a.settlementDate));
    return movements;
  },

  transactions: state => {
    const pendingMovements = [];
    if (!state.fundsData) {
      return pendingMovements;
    }
    state.fundsData.forEach(fund =>
      fund.series.forEach(singleSeries =>
        singleSeries.pendingMovements?.forEach(pendingMovement => {
          const formattedPendingMovement = pendingMovement;
          formattedPendingMovement.series = { ...singleSeries };
          formattedPendingMovement.series.fund = { ...fund };
          delete formattedPendingMovement.series.pendingMovements;
          delete formattedPendingMovement.series.fund.series;
          pendingMovements.push(formattedPendingMovement);
        }),
      ),
    );
    return pendingMovements;
  },

  profile: state => {
    if (!state.profileData) {
      return {};
    }
    return state.profileData;
  },

  clpBankAccount: state => {
    const { profileData } = state;

    if (!profileData || !profileData.bankAccounts) return {};

    const bankAccount = profileData.bankAccounts.find(bankAccount => bankAccount?.currency === "CLP" || !bankAccount?.currency);
    if (!bankAccount) return null;

    const bankData = bankSelects.data().bankNames.find(
      bank => bank.id === Number(bankAccount.bank?.id),
    );

    const accountTypeData = bankSelects.data().availableBankAccountTypes.find(
      accountType => accountType.id === Number(bankAccount?.bankAccountType?.id),
    );

    return {
      accountId: bankAccount?.id,
      accountNumber: bankAccount?.accountNumber,
      bankData,
      accountTypeData,
      bankName: bankAccount.bank?.name,
      bankId: bankAccount.bank?.id,
      accountType: bankAccount?.bankAccountType?.typeName,
      accountTypeId: bankAccount?.bankAccountType?.id,
      updatedAt: bankAccount?.updatedAt,
    };
  },

  balanceHistory: state => {
    const fundNames = {};
    const balanceHistoryDict = {};
    if (!state.balanceHistory) {
      return balanceHistoryDict;
    }
    // Store all runs and types to know what are we expecting to recieve. If we don't get some
    // of the data, this means that the balance for the serie was not created at the specific date.
    // To handle this, we need to create some points with value 0 for these dates.
    const runs = new Set();
    const balanceTypes = new Set();
    const balanceHistoryKeys = [];
    state.balanceHistory.forEach(fund => {
      if (!fund.series.some(serie => serie.balancesHistory.length > 0)) {
        return;
      }
      const { run } = fund;
      // Add the shortname to fundNames dict, so we can access the names later
      // to add them as legends to the svg image.
      fundNames[run] = fund.shortName;
      runs.add(run);
      fund.series.forEach(series => {
        series.balancesHistory.forEach(balanceHistory => {
          const { balanceType } = balanceHistory;
          balanceTypes.add(balanceType);
          balanceHistory.balanceHistoryPoints.forEach(balanceHistoryPoint => {
            const { date } = balanceHistoryPoint;
            if (!(date in balanceHistoryDict)) {
              balanceHistoryDict[date] = {};
            }
            if (!(run in balanceHistoryDict[date])) {
              balanceHistoryDict[date][run] = {};
            }
            if (!(balanceType in balanceHistoryDict[date][run])) {
              balanceHistoryDict[date][run][balanceType] = 0;
            }
            // This needs to be an addition to handle cases where the user has more than one
            // series for the same fund.
            balanceHistoryDict[date][run][balanceType] += balanceHistoryPoint.currencyAmount;
          });
        });
      });
    });
    runs.forEach(run => {
      balanceTypes.forEach(balanceType => {
        if (balanceType !== "CURRENT") {
          const key = `${run}-${balanceType}`;
          balanceHistoryKeys.push(key);
        }
      });
    });
    return {
      fundNames,
      balanceHistoryKeys,
      balanceHistoryDict,
    };
  },

  investedCustomer: (state, getters) => {
    if (state.loading) return true;
    return getters.fundsResults.some(fund => fund?.fundDeposits > 0);
  },

  previousInvestor: (state, getters) => {
    if (state.loading) return false;
    return getters.investedCustomer && getters.fundsResults.every(fund => fund?.fundCurrent === 0);
  },

  displayRecentInvestmentAlert: (state, getters) => {
    if (state.loading) return false;
    if (getters.investTime) {
      const dateMinutesDifference = dayjs().diff(getters.investTime, "minute");
      // If the difference is higher than 10 minutes, we are going to clean the invest time
      if (dateMinutesDifference <= 10) {
        return true;
      }
    }
    return false;
  },

  authenticatedStatus: get("authenticatedStatus"),

  loading: get("loading"),

  loadingBalanceHistory: get("loadingBalanceHistory"),

  loadingProfile: get("loadingProfile"),

  loadingPendingMovements: get("loadingPendingMovements"),

  loadingMovements: get("loadingFundsMovements"),

  loadingReinvestments: get("loadingReinvestments"),

  loadingCustomerDividends: get("loadingCustomerDividends"),

  loadingTransactions: getters => (
    getters.loadingPendingMovements || getters.loadingReinvestments
  ),

  hasTransactions: (state, getters) => {
    if (getters.loadingTransactions) return false;

    return (
      getters.reinvestments.length > 0 ||
        getters.pendingMovements.length > 0
    );
  },

  pendingMovements: get("pendingMovementsData"),

  fintocStatus: get("fintocStatus"),

  targetFund: get("targetFund"),

  referralSource: state => {
    if (!state.signup) return null;
    return state.signup.referralSource;
  },

  utmSource: get("utmSource"),

  utmContent: get("utmContent"),

  utmCampaign: get("utmCampaign"),

  redemption: get("redemptionData"),

  reinvestment: get("reinvestmentData"),

  reinvestments: get("reinvestmentsData"),

  invest: get("investData"),

  signupDeclarations: state => {
    if (!state.signup) return null;
    return {
      isPep: state.signup.isPep || false,
      isCrs: state.signup.isCrs || false,
      isFatca: state.signup.isFatca || false,
      capitalOriginCountry: state.signup.capitalOriginCountry || "CHL",
    };
  },

  signupProfilingAndLaborData: (state, getters) => {
    if (!state.signup) return null;

    return {
      profilingScore: getters.profilingScore,
      selfEmployed: state.signup.selfEmployed,
      profession: state.signup.profession,
      occupation: state.signup.occupation,
      referredFrom: state.signup.referredFrom,
      netWorth: state.signup.netWorth,
      salaryRange: state.signup.salaryRange,
      capitalOrigin: state.signup.capitalOrigin,
    };
  },

  signupEmail: state => {
    const localEmail = localStorage.getItem("userEmail");
    return state.signup.email || localEmail;
  },

  signupTin: state => state.signup.taxIdentificationNumber,

  mutationErrors: get("mutationErrors"),

  latestMutationError: state => state.mutationErrors[state.mutationErrors.length - 1] || {},

  signupProfilingIdentificationError: state => {
    if (!state.mutationErrors.length === 0) return false;
    const profilingFlowMutations = ["signUpIdentification", "signUpProfiling"];

    const savedMutationErrorNames = state.mutationErrors.map(error => error.name);

    return savedMutationErrorNames.some(mutation => profilingFlowMutations.includes(mutation));
  },

  proposedEmail: state => {
    if (!state.signup) return null;
    return state.signup.proposedEmail;
  },

  signupValidationData: state => (
    {
      customerIdentifier: state.signup.rut,
      documentNumber: state.signup.documentNumber,
      front: state.signup.frontImage,
      back: state.signup.backImage,
    }),

  signupIdentificationData: state => (
    {
      name: state.signup.name,
      fatherFamilyName: state.signup.fatherFamilyName,
      motherFamilyName: state.signup.motherFamilyName,
      nacionality: state.signup.nationality,
      birthCountry: state.signup.birthCountry,
      maritalStatus: state.signup.maritalStatus,
      birthdate: state.signup.birthdate,
      gender: state.signup.gender,
      spouseName: state.signup.spouseName,
      spouseFamilyName: state.signup.spouseFamilyName,
      spouseIdentifier: state.signup.spouseIdentifier,
      conjugalRegime: state.signup.conjugalRegime,
      phone: state.signup.phone,
      street: state.signup.street,
      houseNumber: state.signup.houseNumber,
      region: state.signup.region,
      locality: state.signup.locality,
      interiorNumber: state.signup.interiorNumber,
    }),

  signupStatus: state => state.signup.status,

  reinvestDividends: state => state.signup.reinvestDividends,

  profilingScore: state => state.signup.profiling.manual ||
     state.signup.profiling.profilingAnswers.reduce((sum, a) => sum + a, 0),

  dividendDeclarationAnswer: get("dividendDeclarationAnswer"),

  customerDividends: get("customerDividendsData"),

  customerPendingDividends: (state, getters) => {
    if (state.loadingCustomerDividends || !getters.customerDividends || getters.customerDividends.length === 0) return null;

    // Filter the not answered dividends declarations
    const notAnsweredDividends = getters.customerDividends.filter(dividend => dividend.dividendAnswer === null);

    if (notAnsweredDividends.length === 0) return null;

    // Extracts the fund runs from the not answered dividends
    const fundRuns = notAnsweredDividends.map(dividend => dividend.fundSeriesDividend.fundSeries.fundRun);

    // Extracts the fund names from the fund runs using the funds mixin
    const fundNames = fundRuns.map(run => fundsMixin.methods.getFundStaticData(run).shortName);

    // Display the fund names in a single string
    function formatDividendFundNames(fundNames) {
      if (fundNames.length === 1) return fundNames[0];
      if (fundNames.length === 2) return `${fundNames[0]} y ${fundNames[1]}`;
      return `${fundNames.slice(0, -1).join(", ")} y ${fundNames[fundNames.length - 1]}`;
    }

    // Extracts the dividend declaration data
    function getDividendDeclarationData(dividends) {
      return dividends.map(dividend => ({
        fundRun: dividend.fundSeriesDividend.fundSeries.fundRun,
        dividendId: dividend?.id,
        paymentDate: dividend.fundSeriesDividend?.fundDividend?.paymentDate,
        reinvestmentReceiptDate:
          dividend.fundSeriesDividend?.fundDividend?.reinvestmentReceiptDate,
      }));
    }

    // Data to be displayed in the pending dividend declaration flow
    const pendingDividendDeclarationData = {
      dividendList: getDividendDeclarationData(notAnsweredDividends),
      fundDividendNames: formatDividendFundNames(fundNames),
    };

    return pendingDividendDeclarationData;
  },

  investTime: get("investTime"),

  signupRut: state => state.signup.rut,

  expiredOrMissingIdDocument: (state, getters) => {
    if (state.loading) return false;

    // Check if the customer profile exists
    if (getters.profile) {
    // Extract document expiration date and document number from the profile
      const { documentExpirationDate, documentNumber } = getters.profile;

      // If there is no expiration date but there is a document number, assume the ID is valid
      if (!documentExpirationDate && documentNumber) return false;

      // If there is no document number, assume the ID has expired
      if (!documentNumber) return true;

      // Calculate the days until the document expires
      const daysToExpiration = dayjs(documentExpirationDate).diff(dayjs(), "day");

      // If daysToExpiration is negative, the document has expired
      return daysToExpiration < 0;
    }
    // If no profile exists, assume the ID has not expired
    return false;
  },

  hasLaborData: (state, getters) => {
    if (state.loading) return false;
    if (getters.profile) {
      // Returns if the customer has registered selfEmployed, profession and occupation
      const { selfEmployed, profession, occupation } = getters.profile;

      return isboolean(selfEmployed) && !!profession && !!occupation;
    }
    return false;
  },

  isUnderage: (state, getters) => {
    if (state.loading) return false;

    if (getters.profile?.birthdate) {
      const currentDate = dayjs();
      return currentDate.diff(dayjs(getters.profile.birthdate), "year") < 18;
    }

    return false;
  },

  entityType: (state, getters) => {
    if (state.loading) return null;

    if (getters.profile) {
      return getters.profile.entityType;
    }

    return null;
  },

  isJuridicPerson: (state, getters) => {
    if (state.loading) return false;

    if (getters.profile) {
      return getters.profile.entityType === "J";
    }

    return false;
  },

  isPrincipalEmail: (state, getters) => {
    if (state.loading) return false;

    if (getters.profile) {
      // The email used to login
      const sessionEmail = localStorage.getItem("userEmail");

      // Checks if the list of principal emails includes the session email
      return getters.profile.emails.filter(emailItem => emailItem.principal).map(emailItem => emailItem.email).includes(sessionEmail);
    }

    return false;
  },

  customerRiskProfile: (state, getters) => {
    if (state.loading) return "loading";

    const riskProfiles = [
      {
        type: "low", scoreRange: [6, 8],
      },
      {
        type: "belowAverage", scoreRange: [9, 11],
      },
      {
        type: "average", scoreRange: [12, 14],
      },
      {
        type: "aboveAverage", scoreRange: [15, 17],
      },
      {
        type: "high", scoreRange: [18, 24],
      },
    ];

    const { profiling } = getters.profile || {};

    if (!profiling) return "none";

    return riskProfiles.find(({ scoreRange }) =>
      profiling >= scoreRange[0] && profiling <= scoreRange[scoreRange.length - 1],
    ).type || "none";
  },

  isSignedUp: (state, getters) => {
    if (state.loading) return false;

    if (getters.profile) {
      // If the user is signed up, the profile getter has user id
      return !!getters.profile?.id;
    }

    return false;
  },

  customerAddress: state => state.profileData.addresses[0],

  customerPhone: state => state.profileData.phones[0]?.phone,

  customerSpouse: state => state.profileData.spouses[0],

  customerTin: state => state.profileData.taxIdentificationNumbers[0],

  customerDeclarations: state => (
    {
      isPep: state.profileData.isPep,
      isCrs: state.profileData.isCrs,
      isFatca: state.profileData.isFatca,
      capitalOriginCountry: state.profileData.capitalOriginCountry,
      capitalOrigin: state.profileData.capitalOrigin,
      netWorth: state.profileData.netWorth,
      salaryRange: state.profileData.salaryRange,
      tinCountry: state.profileData.taxIdentificationNumbers[0]?.country || null,
      tinIdentification: state.profileData.taxIdentificationNumbers[0]?.identification || null,
      // reinvestDistributions: state.profileData.reinvestDistributions,
    }
  ),

  nextRouteFromStack: state => state.routeStack[state.routeStack.length - 1],

  personalDataAlertCount: (state, getters) => {
    if (state.loadingProfile) return 0;

    const {
      profile, customerSpouse, isUnderage, isJuridicPerson,
    } = getters;

    if (isUnderage || isJuridicPerson) return 0;

    // Checks if the customer is married or in a civil union
    const needsMaritalData = profile?.maritalStatus === "MARRIED" || profile?.maritalStatus === "CIVIL_UNION";

    const profileFields = [
      profile.name,
      profile.lastName,
      profile.birthDate,
      profile.maritalStatus,
      profile.occupation,
      profile.profession,
      profile.selfEmployed,
      profile.nationality,
      profile.birthCountry,
    ];

    // If the customer is married or in a civil union, add the spouse data to the profile fields
    if (needsMaritalData) {
      profileFields.push(customerSpouse?.name || null);
      profileFields.push(customerSpouse?.lastName || null);
      profileFields.push(customerSpouse?.identifier || null);
      profileFields.push(profile?.conjugalRegime || null);
    }

    // Count the number of empty fields in the customer profile
    const alertFieldsCount = profileFields.reduce((count, field) => (field === null || field === "" ? count + 1 : count), 0);

    return alertFieldsCount;
  },

  idDocumentAlertCount: (state, getters) => {
    if (state.loadingProfile) return 0;

    const { expiredOrMissingIdDocument, isUnderage, isJuridicPerson } = getters;

    if (isUnderage || isJuridicPerson) return 0;

    return expiredOrMissingIdDocument ? 1 : 0;
  },

  contactAddressAlertCount: (state, getters) => {
    if (state.loadingProfile) return 0;

    const phone = getters.customerPhone;
    const address = getters.customerAddress;

    const { isUnderage, isJuridicPerson } = getters;

    if (isUnderage || isJuridicPerson) return 0;

    if (!address) return 4;

    const alertFieldsCount = [
      phone,
      address.region,
      address.locality,
      address.streetName,
      address.houseNumber,
    ].reduce((count, field) => (field === null || field === "" ? count + 1 : count), 0);

    return alertFieldsCount;
  },

  bankingDataAlertCount: (state, getters) => {
    if (state.loadingProfile) return 0;

    const { clpBankAccount, isUnderage, isJuridicPerson } = getters;

    if (isUnderage || isJuridicPerson) return 0;

    if (!clpBankAccount) return 3;

    const alertFieldsCount = [
      clpBankAccount.accountNumber,
      clpBankAccount.bankName,
      clpBankAccount.accountType,
    ].reduce((count, field) => (field === null || field === "" ? count + 1 : count), 0);

    return alertFieldsCount;
  },

  riskProfileAlertCount: (state, getters) => {
    if (state.loadingProfile) return 0;

    const { isUnderage, isJuridicPerson } = getters;

    if (isUnderage || isJuridicPerson) return 0;

    const { profiling } = getters.profile;

    return !profiling ? 1 : 0;
  },

  documentsAlertCount: (state, getters) => {
    if (state.loadingProfile) return 0;

    const { canSignCompassContract } = getters;

    return (canSignCompassContract) ? 1 : 0;
  },

  declarationsAlertCount: (state, getters) => {
    if (state.loadingProfile) return 0;

    const { profile, isUnderage, isJuridicPerson } = getters;

    if (isUnderage || isJuridicPerson) return 0;

    const alertFieldsCount = [
      profile.capitalOrigin,
      profile.capitalOriginCountry,
      profile.isPep,
      profile.isCrs,
      profile.isFatca,
    ].reduce((count, field) => (field === null || field === "" ? count + 1 : count), 0);

    return alertFieldsCount;
  },

  transactionalProfileAlertCount: (state, getters) => {
    if (state.loadingProfile) return 0;

    const { profile, isUnderage, isJuridicPerson } = getters;

    if (isUnderage || isJuridicPerson) return 0;

    const alertFieldsCount = [
      profile.netWorth,
      profile.salaryRange,
    ].reduce((count, field) => (field === null || field === "" ? count + 1 : count), 0);

    return alertFieldsCount;
  },

  accountAlertCount: (state, getters) => {
    if (state.loadingProfile) return 0;

    const pendingGetters = [
      "personalDataAlertCount",
      "idDocumentAlertCount",
      "contactAddressAlertCount",
      "bankingDataAlertCount",
      "riskProfileAlertCount",
      "documentsAlertCount",
      "declarationsAlertCount",
      "transactionalProfileAlertCount",
    ];

    return pendingGetters.reduce((total, getter) => total + getters[getter], 0);
  },

  isMissingSignupData: (state, getters) => {
    if (state.loadingProfile) return false;

    const pendingGetters = [
      "personalDataAlertCount",
      "idDocumentAlertCount",
      "contactAddressAlertCount",
      "riskProfileAlertCount",
      "declarationsAlertCount",
    ];

    return pendingGetters.reduce((total, getter) => total + getters[getter], 0) > 0;
  },

  canSignCompassContract: (state, getters) => {
    if (state.loadingProfile) return false;

    const { completedContractCompass } = getters.signupStatus;

    const {
      isPrincipalEmail, entityType, isUnderage, isMissingSignupData,
    } = getters;

    return (
      !completedContractCompass &&
        isPrincipalEmail &&
        !isUnderage &&
        !isMissingSignupData &&
        entityType === "N"
    );
  },

  displayCompassNoticeBar: (state, getters) => {
    if (state.loadingProfile) return false;

    const { completedContractCompass } = getters.signupStatus;

    const { isPrincipalEmail, entityType, isUnderage } = getters;

    return (
      !completedContractCompass &&
        isPrincipalEmail &&
        !isUnderage &&
        entityType === "N"
    );
  },

  isMissingTransactionalProfileData: (state, getters) => {
    if (state.loadingProfile) return false;

    const { netWorth, salaryRange } = getters.profile;

    // Checks if the customer is missing the transactional data
    return !netWorth || !salaryRange;
  },

  signupCompleted: (state, getters) => {
    if (state.loading) return false;

    const { completedContract, completedContractCompass } = getters.signupStatus;

    return completedContractCompass || completedContract;
  },

  currentUser: state => {
    const { name: signupName, email: signupEmail } = state.signup;

    const localProfile = localStorage.getItem("profile");

    if (localProfile) {
      return Vue.filter("getFullName")(JSON.parse(localProfile));
    }

    return signupName || signupEmail || localStorage.getItem("userEmail");
  },

};

export default {
  actions,
  getters,
};
