<template>
  <div class="invest-wrapper">
    <div class="invest-content invest-bank-container">
      <div class="invest-step-back">
        <v-icon color="#333333" size="22" @click="performStepBack()">
          mdi-arrow-left
        </v-icon>
      </div>
      <div class="invest-amount-title-container">
        <span class="invest-amount-title">
          {{ $t("investment.bankSelect.label") }}
          {{ selectedFund | fundShortName }}
        </span>
        <v-icon
          dark
          size="10"
          class="mx-2 my-auto fund-icon"
          :style="`background-color: ${fundColor}`"
        >
          {{ fundIcon }}
        </v-icon>
      </div>

      <div class="invest-amount-subtitle">
        {{ $t("investment.bankSelect.title") }}
      </div>

      <v-row class="bank-cards-container pb-10" dense>
        <v-col
          cols="12"
          sm="6"
          md="4"
          v-for="bank in bankNames"
          :key="bank.id"
          @click="selectBank(bank.id)"
        >
          <BankCard class="bank-card" :bankId="bank.id" />
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import funds from "@/mixins/funds.js";
import { bankSelects } from "@/mixins/bankSelects.js";
import BankCard from "@/components/investment/cards/BankCard.vue";

export default {
  name: "BankSelect",

  mixins: [funds, bankSelects],

  components: { BankCard },

  computed: {
    ...mapGetters(["invest"]),

    selectedFund() {
      return this.invest.fundRun;
    },

    fundColor() {
      return this.$options.filters.addFundColor(this.selectedFund);
    },

    fundIcon() {
      return `mdi-${this.getFundStaticData(this.selectedFund).icon}`;
    },
  },

  methods: {
    ...mapActions(["setInvestData", "popFromRouteStack"]),

    selectBank(bankId) {
      this.setInvestData({ ...this.invest, bankId });
      if (!this.getFintocBankId(bankId)) {
        this.$router.push({ name: "investBankTransfer" });
      } else {
        this.$router.push({ name: "investSelectTransferOption" });
      }
    },

    getFintocBankId(bankId) {
      return this.bankNames.find(bank => bank.id === bankId).fintocId;
    },

    performStepBack() {
      // Deletes the last route from the route stack
      this.popFromRouteStack();
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss" scoped>
.invest-amount-title {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
}

.invest-amount-subtitle {
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 160%;
  color: #333333;

  @media (max-width: 600px) {
    font-size: 18px;
  }
}

.button-col-group {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}

.back-btn {
  margin: 0 24px 0 -40px;
  @media (max-width: 600px) {
    margin: 0 3px 0 -10px;
  }
}

.fund-icon {
  border-radius: 50%;
  height: 18px;
  width: 18px;
}

.custom-input {
  margin: 40px 0;
  @media (max-width: 960px) {
    padding-right: 12px;
  }
}

.bank-cards-container {
  padding-bottom: 50px;
}

.row--dense > .col,
.row--dense > [class*="col-"] {
  padding: 5px !important;
}
</style>
